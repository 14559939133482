<template>
   <div class="ShowGoodsDetail">
       <div style="width: 375px;height:580px ">
        <el-image :src="require('@/assets/img/titlebar.png')" style="display:block"></el-image>
          <el-scrollbar style="height:580px" >
            <div class="border_left  border_right border_bottom" >
                <!-- 轮播 -->
              <el-carousel height="250px" arrow="never">
                <el-carousel-item v-for="(item, index) in ImageList_" :key="index" >
                  <el-image style="width: 100%; height: 100%" :src="item.url" fit="fill" ></el-image>
                </el-carousel-item>
              </el-carousel>
              
              <el-row class="pad_10" style="border-bottom: 5px solid #eeeeee;">
                <el-col :span="24">
                  <span class="font_15 font_weight_600 color_red"> ¥ {{Price | NumFormat}}</span>
                  <span v-if="isShowOriginal" class="font_12 color_gray marlt_20" style="text-decoration:line-through">{{OriginalText_ }}</span>
                </el-col>

                <el-col :span="24" class="martp_5" >
                  <el-tag type="danger" size="small">{{GoodsType}}</el-tag>
                  <span class="font_13 color_333 marlt_10">{{Name}}</span>
                </el-col>
              </el-row>
              <div >
                 <slot name="info"></slot>
              </div>
              <el-row class="pad_10">
                <el-col :span="24" v-html="Memo_1">商品详情</el-col>
              </el-row>
            </div>
          </el-scrollbar>
      </div>
   </div>
</template>

<script>
export default {
    Name: 'ShowGoodsDetail',
    props:{
      ImageList:{
        type:Array,
        require:true
      },
      Memo:{
        type:String,
      },
      OriginalText:{
        type:String,
      },
      // 是否显示划线价格
      isShowOriginal:{
        type:Boolean,
        default:true
      },
      Price:{},
      Name:{
        type:String,
      },
      GoodsType:{
        type:String,
      },

    },
    data() {
     return {
      editorOption:"",
      ImageList_:[],
      Memo_:"",
      Memo_1:"",
      OriginalText_:"",
      dragOptions() {
        return {
          animation: 200,
        };
      },
     }
    },
    /** 监听数据变化   */
  watch: {
    ImageList(newVal){
      this.ImageList_ = newVal;
    },
    Memo(newVal){
      this.Memo_ = newVal;
      this.Memo_1 = newVal;
    },
    OriginalText(newVal){
      this.OriginalText_ = newVal;
    }
  },
    /**  方法集合  */
    methods: {},
    /**  实例创建完成之后  */
    created() {
    }
}
</script>

<style lang="scss">
  .GoodsDetail{}
</style>