<template>
  <div class="GoodsDetail">
    <div class="dis_flex flex_dir_row">
      <div style="width: 375px;height:580px ">
        <el-image :src="require('@/assets/img/titlebar.png')" style="display:block"></el-image>
        <el-scrollbar style="height:580px">
          <div class="border_left  border_right border_bottom">
            <el-carousel height="250px" arrow="never">
              <el-carousel-item v-for="(item, index) in ImageList_" :key="index">
                <el-image style="width: 100%; height: 100%" :src="item.url" fit="fill"></el-image>
              </el-carousel-item>
            </el-carousel>

            <el-row class="pad_10" style="border-bottom: 5px solid #eeeeee;">
              <el-col :span="24">
                <span class="font_15 font_weight_600 color_red"> ¥ {{Price | NumFormat}}</span>
                <span v-if="isShowOriginal" class="font_12 color_gray marlt_20" style="text-decoration:line-through">{{OriginalText_ }}</span>
              </el-col>

              <el-col :span="24" class="martp_5">
                <el-tag type="danger" size="small">{{GoodsType}}</el-tag>
                <span class="font_13 color_333 marlt_10">{{Name}}</span>
              </el-col>
            </el-row>
            <div>
              <slot name="info"></slot>
            </div>
            <el-row class="pad_10">
              <el-col :span="24" class="ql-editor" v-html="Memo_1">商品详情</el-col>
            </el-row>
          </div>
        </el-scrollbar>
      </div>
      <div class="marlt_10 flex_box">
        <el-scrollbar class="el-scrollbar_height" style="height:580px">
          <el-form label-width="120px" size="small">
            <el-form-item label="商品主图:">
              <div class="switchBox pad_0_10 dis_flex flex_x_between flex_y_center">
                <span>主图<span class="marlt_10 color_999">最多上传五张，建议尺寸710*278px</span></span>
              </div>

              <el-upload :limit="5" class="avatar-uploader" list-type="picture-card" action="#" :file-list="ImageList_" :before-upload="commodityMainbeforeUpload" :on-remove="commodityMainRemove">
                <i class="el-icon-plus avatar-uploader-icon"></i>

                <div slot="file" slot-scope="{file}" style="height:100px;widht:100px">
                  <!-- <draggable tag="span"  v-bind="dragOptions"> -->
                  <el-image class="el-upload-list__item-thumbnail" :id="file.uid" :src="file.url" :preview-src-list="preview_src_list" :z-index="9999" fit="cover"></el-image>
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="DialogPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-preview" @click="commodityMainRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                  <!-- </draggable> -->
                </div>

              </el-upload>

            </el-form-item>
            <el-form-item v-if="isShowOriginal" label="划线价格:">
              <el-input size="small" v-model="OriginalText_" @change="OriginalText_Change"></el-input>
            </el-form-item>
            <el-form-item label="商品详情:">
              <quill-editor ref="myTextEditor" v-model="Memo_" :options="editorOption" style="width: 90%; height: 400px" @change="onEditorChange($event)"></quill-editor>
            </el-form-item>
          </el-form>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import draggable from "vuedraggable";
import Enumerable from "linq";

export default {
  Name: "GoodsDetail",
  props: {
    ImageList: {
      type: Array,
      require: true,
    },
    Memo: {
      type: String,
    },
    OriginalText: {
      type: String,
    },
    // 是否显示划线价格
    isShowOriginal: {
      type: Boolean,
      default: true,
    },
    Price: {},
    Name: {
      type: String,
    },
    GoodsType: {
      type: String,
    },
  },
  /**  引入的组件  */
  components: {
    quillEditor,
    draggable,
  },
  /**  Vue 实例的数据对象**/
  data() {
    return {
      editorOption: "",
      ImageList_: [],
      Memo_: "",
      Memo_1: "",
      OriginalText_: "",
      dragOptions() {
        return {
          animation: 200,
        };
      },
      preview_src_list: [],
    };
  },
  /**计算属性  */
  computed: {},
  /** 监听数据变化   */
  watch: {
    ImageList(newVal) {
      this.ImageList_ = newVal;
      this.preview_src_list = [];
      this.preview_src_list = Enumerable.from(this.ImageList_)
        .select((val) => val.url)
        .toArray();
    },
    Memo(newVal) {
      this.Memo_ = newVal;
      // this.Memo_1 = newVal;
    },
    OriginalText(newVal) {
      this.OriginalText_ = newVal;
    },
  },
  /**  方法集合  */
  methods: {
    /**  划线价格变化  */
    OriginalText_Change(val) {
      let that = this;
      that.$emit("OriginalText_Change", val);
    },
    /**  监听富文本 内容变化  */
    onEditorChange({ html }) {
      let that = this;
      const regex = new RegExp("<img", "gi");
      that.Memo_1 = html.replace(regex, `<img style="max-width: 100%;"`);
    },
    getMemo() {
      let that = this;
      return  that.Memo_1?that.Memo_1:"";
    },

    /**  商品主图上传  */
    commodityMainbeforeUpload(file) {
      let that = this;
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        that.$message.error("上传头像图片大小不能超过 3MB!");
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (evt) {
        let base64 = evt.target.result;
        that.$nextTick(() => {
          that.ImageList_.push({ url: base64 });
          that.$emit("ImageListChange", that.ImageList_);
        });
      };
      return false;
    },

    /**    */
    commodityMainRemove(file) {
      if (file && file.status !== "success") return;
      let that = this;

      let index = that.ImageList_.findIndex((item) => item.url == file.url);
      that.ImageList_.splice(index, 1);
      that.$emit("ImageListChange", that.ImageList_);
    },
    // 查看大图
    DialogPreview(file) {
      document.getElementById(file.uid).click();
    },
  },
  /**  实例创建完成之后  */
  created() {
    let that = this;
    that.ImageList_ = that.ImageList;

    that.Memo_ = that.Memo;
    that.Memo_1 = that.Memo;
    that.OriginalText_ = that.OriginalText;

    that.preview_src_list = [];
    that.preview_src_list = Enumerable.from(that.ImageList_)
      .select((val) => val.url)
      .toArray();
    //  富文本编辑器配置
    var toolbarOptions = [
      ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
      ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
      [{ header: 1 }, { header: 2 }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
      [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
      [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
      [{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
      [{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
      [{ size: ["small", false, "large", "huge"] }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
      [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
      [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
      [{ font: [] }], // 字体种类-----[{ font: [] }]
      [{ align: [] }], // 对齐方式-----[{ align: [] }]
      ["clean"], // 清除文本格式-----['clean']
      ["image"],
    ]; // 链接、图片、视频-----['link', 'image', 'video']

    that.editorOption = {
      modules: {
        //工具栏定义的
        toolbar: toolbarOptions,
      },
      //主题
      theme: "snow",
      placeholder: "请输入正文",
    };
  },
  /**  实例被挂载后调用  */
  mounted() {},
};
</script>

<style lang="scss">
.GoodsDetail {
  .avatar-uploader {
    .el-upload--picture-card {
      width: 100px;
      height: 100px;
      font-size: 16px !important;
    }
    .el-upload {
      width: 100px;
      height: 100px;
      line-height: 100px;
      font-size: 16px;
    }
    .el-upload-list--picture-card .el-upload-list__item {
      width: 100px;
      height: 100px;
      line-height: 100px;
      font-size: 16px;
    }
  }
  .el-scrollbar_height {
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
}
</style>
