<template>
  <div class="content_body GeneralCard" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
            <el-form-item label="通用次卡名称">
              <el-input v-model="searchKey" @clear="handleSearch" clearable placeholder="输入通用次名称或别名"></el-input>
            </el-form-item>
            <el-form-item label="通用次卡分类">
              <el-select @change="handleSearch" @clear="handleSearch" v-model="generalCardCategoryID" placeholder="请选择通用次卡分类" clearable filterable>
                <el-option v-for="item in generalCardCategoryList" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否上架">
              <el-select @change="handleSearch" @clear="handleSearch" v-model="isUpperShelf" placeholder="请选择是否上架" clearable>
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button v-if="isGeneralCardNew" type="primary" size="small" @click="showAddDialog" v-prevent-click>新增</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="martp_10">
      <el-table size="small" ref="multipleTable" :data="generalCardList">
        <el-table-column prop="Name" label="通用次卡名称"></el-table-column>
        <el-table-column prop="Alias" label="通用次卡别名"></el-table-column>
        <el-table-column prop="GeneralCardCategoryName" label="通用次卡分类"></el-table-column>
        <el-table-column prop="Price" label="销售价格">
          <template slot-scope="scope">￥{{ scope.row.Price | NumFormat }}</template>
        </el-table-column>
        <el-table-column prop="Amount" label="通用次卡次数"></el-table-column>
        <el-table-column prop="ValidDayName" label="有效期"></el-table-column>
        <el-table-column label="是否可以赠送">
          <template slot-scope="scope">{{
            scope.row.IsAllowLargess ? "是" : "否"
          }}</template>
        </el-table-column>
        <el-table-column label="是否上架">
          <template slot-scope="scope">{{
            scope.row.IsAllowSell ? "是" : "否"
          }}</template>
        </el-table-column>
        <el-table-column label="操作" width="145">
          <template slot-scope="scope">
            <el-button v-if="isGeneralCardEdit" type="primary" size="small" @click="showEditDialog(scope.row,'edit')" v-prevent-click>编辑</el-button>
            <el-button type="primary" size="small" @click="showEditDialog(scope.row,'show')" v-prevent-click>详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 dis_flex flex_x_between" v-if="false">
        <div>
          <el-dropdown trigger="click" @command="dropdownClick" size="small">
            <el-button type="primary" size="small" v-prevent-click>
              批量设置
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">分类</el-dropdown-item>
              <el-dropdown-item command="2">售卖日期</el-dropdown-item>
              <el-dropdown-item command="3">卡有效期</el-dropdown-item>
              <el-dropdown-item command="4">是否上架</el-dropdown-item>
              <el-dropdown-item command="5">是否可以赠送</el-dropdown-item>
              <el-dropdown-item command="6">销售范围</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="text_right pad_15">
        <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
      </div>
    </div>
    <!--增加、编辑弹出框-->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" width="980px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="1">
          <div class="pad_15">
            <el-form :model="ruleForm_add" :rules="rules_add" ref="ruleForm_add" label-width="120px" size="small">
              <el-col :span="12">
                <el-form-item label="通用次卡名称" prop="Name">
                  <el-input v-model="ruleForm_add.Name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="通用次卡别名">
                  <el-input v-model="ruleForm_add.Alias"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="通用次卡分类" prop="GeneralCardCategoryID">
                  <el-select style="position: absolute" v-model="ruleForm_add.GeneralCardCategoryID" placeholder="请选择通用次卡分类" clearable filterable>
                    <el-option v-for="item in generalCardCategoryList" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
                  </el-select>
                  <!--                   <span class="marlt_20 color_primary" @click="addClassification">添加</span>-->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="卡次数" prop="Amount">
                  <el-input v-model="ruleForm_add.Amount" type="number" v-enter-number>
                    <template slot="append">次</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="销售价格" prop="Price">
                  <el-input v-model="ruleForm_add.Price" v-positiveNumber>
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="售卖日期" prop="saleDate">
                  <el-date-picker clearable size="small" v-model="ruleForm_add.saleDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否上架" prop="IsAllowSell">
                  <el-radio-group v-model="ruleForm_add.IsAllowSell">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否可赠送" prop="IsAllowLargess">
                  <el-radio-group v-model="ruleForm_add.IsAllowLargess">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="卡有效期" prop="ValidType">
                  <div>
                    <el-radio-group v-model="ruleForm_add.ValidType">
                      <el-radio :label="3">永久有效</el-radio>
                      <el-radio :label="1">销售后生成</el-radio>
                      <el-radio :label="2">消耗后生成</el-radio>
                    </el-radio-group>
                    <span v-if="ruleForm_add.ValidType != 3">
                      <el-input class="radio_input" v-model="ruleForm_add.ValidDay" type="number" v-enter-number v-enterNumber2 min="0">
                        <template slot="append">天</template>
                      </el-input>
                    </span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注信息">
                  <el-input type="textarea" :rows="4" placeholder="请输入备注信息" v-model="ruleForm_add.Remark"></el-input>
                </el-form-item>
              </el-col>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="详细信息" name="5">
          <div class="dis_flex flex_dir_row">
            <goodsDetail ref="goodsDetail" :Memo="ruleForm_add.Memo" :ImageList="ruleForm_add.ImageList" :OriginalText="ruleForm_add.OriginalText" :Price="ruleForm_add.Price" :Name="ruleForm_add.Name" :isShowOriginal="false" GoodsType="通用次卡" @Memo_change="Memo_change" @ImageListChange="ImageListChange">

              <el-row slot="info">
                <el-col :span="24" class="padlt_10 padrt_10" style="height:40px;line-height:40px">商品信息</el-col>
                <el-col :span="24" class="back_f8 pad_10">
                  <el-col :span="24">卡次数: {{ruleForm_add.Amount}} 次</el-col>
                  <el-col :span="24" class="martp_5">有效期:
                    <span v-if="ruleForm_add.ValidType == 1">购买后 {{ruleForm_add.ValidDay}} 天内有效</span>
                    <span v-if="ruleForm_add.ValidType == 2">第一次使用后 {{ruleForm_add.ValidDay}} 天内有效</span>
                    <span v-if="ruleForm_add.ValidType == 3">永久有效</span>
                  </el-col>
                  <el-col :span="24" class="martp_5">适用项目: 适用共 {{generalCardProjectList.length}} 个项目 <span style="color:#FF8D66">查看详情</span></el-col>
                  <el-col :span="24" class="martp_5">适用门店: 适用共 {{ruleForm_add.ApplyNum}} 家门店 <span style="color:#FF8D66">查看门店</span></el-col>
                </el-col>
              </el-row>
            </goodsDetail>
          </div>

        </el-tab-pane>
        <el-tab-pane label="适用项目" name="4">
          <el-row :gutter="20" class="marbm_10">
            <el-col :span="6">
              <el-input placeholder="输入项目名称搜索" size="small" v-model="filterProjectName" clearable></el-input>
            </el-col>
            <el-col :span="12">
              <el-button type="primary" size="small" @click="addSetDetails">配置适用项目</el-button>
            </el-col>
          </el-row>
          <el-table size="small" :data="
              generalCardProjectList.filter(
                (data) =>
                  !filterProjectName ||
                  data.Name.toLowerCase().includes(
                    filterProjectName.toLowerCase()
                  )
              )
            " max-height="400">
            <el-table-column prop="Name" label="项目名称" sortable></el-table-column>
            <el-table-column prop="ProjectCategoryName" label="项目分类" sortable></el-table-column>
            <el-table-column prop="Price" label="项目销售价格" sortable></el-table-column>
            <el-table-column prop="ConsumeAmount" label="耗卡次数" sortable>
              <template slot="header">
                耗卡次数
                <el-popover placement="top-start" width="200" trigger="hover">
                  <p>通用次卡消耗项目时，项目需要耗掉多少次。</p>
                  <el-button type="text" style="color: #DCDFE6; padding: 0" icon="el-icon-info" slot="reference"></el-button>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="LimitConsumeTimes" label="最高消耗次数" sortable>
              <template slot="header">
                最高消耗次数
                <el-popover placement="top-start" width="200" trigger="hover">
                  <p>通用次卡消耗项目时，项目最多消耗次数。</p>
                  <el-button type="text" style="color: #DCDFE6; padding: 0" icon="el-icon-info" slot="reference"></el-button>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="销售范围" name="2">
          <span slot="label">
            销售范围
            <el-popover placement="top-start" width="200" trigger="hover">
              <p>适用于同级所有节点，则只需勾选父节点。</p>
              <p>比如：适用于所有节点，只需勾选“顶级/第一个”节点。</p>
              <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
            </el-popover>
          </span>
          <el-scrollbar class="el-scrollbar_height">
            <el-tree ref="treeSale" :expand-on-click-node="false" :check-on-click-node="true" :check-strictly="true" :data="generalCardSaleEntityList" show-checkbox node-key="ID" :default-checked-keys="defaultCheckedKeysSale" :default-expanded-keys="defaultExpandedKeysSale" :props="scopeDefaultProps"></el-tree>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane label="消耗范围" name="3">
          <span slot="label">
            消耗范围
            <el-popover placement="top-start" width="200" trigger="hover">
              <p>适用于同级所有节点，则只需勾选父节点。</p>
              <p>比如：适用于所有节点，只需勾选“顶级/第一个”节点。</p>
              <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
            </el-popover>
          </span>
          <el-scrollbar class="el-scrollbar_height">
            <el-tree ref="treeConsume" :expand-on-click-node="false" :check-on-click-node="true" :check-strictly="true" :data="generalCardConsumpEntityList" show-checkbox node-key="ID" :default-checked-keys="defaultCheckedKeysConsume" :default-expanded-keys="defaultExpandedKeysConsume" :props="scopeDefaultProps"></el-tree>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button @click="submitForm('ruleForm_add')" size="small" type="primary" v-prevent-click :loading="updateLoading">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 查看弹出层 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialogVisible" width="980px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="1">
          <div class="pad_15">
            <el-form label-width="150px" class="generalShow" size="small">
              <el-col :span="12">
                <el-form-item label="通用次卡名称：">
                  <span>{{ruleForm_add.Name}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="通用次卡别名：">
                  <span>{{ruleForm_add.Alias}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="通用次卡分类：">
                  <span>{{GeneralCardCategory}}</span>

                  <!--                   <span class="marlt_20 color_primary" @click="addClassification">添加</span>-->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="卡次数：">
                  <span>{{ruleForm_add.Amount}}次</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="销售价格：">
                  <span>{{ruleForm_add.Price}}元</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="售卖日期：">
                  <span>{{saleDate}}</span>
                  <!-- <el-date-picker clearable size="small"  v-model="ruleForm_add.saleDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels></el-date-picker> -->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否上架：">
                  <span v-if="ruleForm_add.IsAllowSell">是</span>
                  <span v-else>否</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否可赠送：">
                  <span v-if="ruleForm_add.IsAllowLargess">是</span>
                  <span v-else>否</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="卡有效期：">
                  <span v-if="ruleForm_add.ValidType==3">永久有效</span>
                  <span v-else-if="ruleForm_add.ValidType==1">销售后生成{{ruleForm_add.ValidDay}}天</span>
                  <span v-else>消耗后生成{{ruleForm_add.ValidDay}}天</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注信息：">
                  <span>{{ruleForm_add.Remark}}</span>
                </el-form-item>
              </el-col>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="详细信息" name="2">
          <div class="dis_flex flex_x_center">
            <showGoodsDetail ref="ShowGoodsDetail" :Memo="ruleForm_add.Memo" :ImageList="ruleForm_add.ImageList" :OriginalText="ruleForm_add.OriginalText" :Price="ruleForm_add.Price" :Name="ruleForm_add.Name" :isShowOriginal="false" GoodsType="通用次卡">

              <el-row slot="info">
                <el-col :span="24" class="padlt_10 padrt_10" style="height:40px;line-height:40px">商品信息</el-col>
                <el-col :span="24" class="back_f8 pad_10">
                  <el-col :span="24">卡次数: {{ruleForm_add.Amount}} 次</el-col>
                  <el-col :span="24" class="martp_5">有效期:
                    <span v-if="ruleForm_add.ValidType == 1">购买后 {{ruleForm_add.ValidDay}} 天内有效</span>
                    <span v-if="ruleForm_add.ValidType == 2">第一次使用后 {{ruleForm_add.ValidDay}} 天内有效</span>
                    <span v-if="ruleForm_add.ValidType == 3">永久有效</span>
                  </el-col>
                  <el-col :span="24" class="martp_5">适用项目: 适用共 {{generalCardProjectList.length}} 个项目 <span style="color:#FF8D66">查看详情</span></el-col>
                  <el-col :span="24" class="martp_5">适用门店: 适用共 {{ruleForm_add.ApplyNum}} 家门店 <span style="color:#FF8D66">查看门店</span></el-col>
                </el-col>
              </el-row>
            </showGoodsDetail>
          </div>
        </el-tab-pane>
        <el-tab-pane label="适用项目" name="4">
          <el-row :gutter="20" class="marbm_10">
            <el-col :span="6">
              <el-input placeholder="输入项目名称搜索" size="small" v-model="filterProjectName" clearable></el-input>
            </el-col>
            <el-col :span="12">
            </el-col>
          </el-row>
          <el-table size="small" :data="
              generalCardProjectList.filter(
                (data) =>
                  !filterProjectName ||
                  data.Name.toLowerCase().includes(
                    filterProjectName.toLowerCase()
                  )
              )
            " max-height="400">
            <el-table-column prop="Name" label="项目名称" sortable></el-table-column>
            <el-table-column prop="ProjectCategoryName" label="项目分类" sortable></el-table-column>
            <el-table-column prop="Price" label="项目销售价格" sortable></el-table-column>
            <el-table-column prop="ConsumeAmount" label="耗卡次数" sortable>
              <template slot="header">
                耗卡次数
                <el-popover placement="top-start" width="200" trigger="hover">
                  <p>通用次卡消耗项目时，项目需要耗掉多少次。</p>
                  <el-button type="text" style="color: #ffffff; padding: 0" icon="el-icon-info" slot="reference"></el-button>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="LimitConsumeTimes" label="最高消耗次数" sortable>
              <template slot="header">
                最高消耗次数
                <el-popover placement="top-start" width="200" trigger="hover">
                  <p>通用次卡消耗项目时，项目最多消耗次数。</p>
                  <el-button type="text" style="color: #ffffff; padding: 0" icon="el-icon-info" slot="reference"></el-button>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <!--添加适用项目弹出框-->
    <el-dialog :visible.sync="addScopeDialogState" width="900px">
      <template slot="title">
        <span>配置通用次卡适用项目</span>
      </template>
      <template>
        <el-row>
          <el-col :span="8">
            <el-input placeholder="输入关键字进行搜索" v-model="filterText" size="small" clearable></el-input>
            <el-scrollbar class="el-scrollbar_height">
              <el-tree class="filter-tree" :data="generalCardProject" show-checkbox node-key="PID" ref="treeRef" accordion highlight-current :props="defaultProps" :default-checked-keys="defaultCheckedKeysApplyApp" :filter-node-method="filterNode" @check="selectApplicableItems">
                <span slot-scope="{ node, data }">
                  <span>{{ data.Name }}</span>
                  <el-tag class="marlt_5" type="info" size="mini" v-if="!data.IsProject">分类</el-tag>
                </span>
              </el-tree>
            </el-scrollbar>
          </el-col>
          <el-col :span="16" class="border_left">
            <el-table size="small" :data="
                selectedTableData.filter(
                  (data) =>
                    !filterText ||
                    data.Name.toLowerCase().includes(filterText.toLowerCase())
                )
              " max-height="500px">
              <el-table-column prop="Name" label="项目名称"></el-table-column>
              <el-table-column prop="Price" label="销售价格"></el-table-column>
              <el-table-column label="耗卡次数" width="135px">
                <template slot="header">
                  耗卡次数
                  <el-popover placement="top-start" width="200" trigger="hover">
                    <p>通用次卡消耗项目时，项目需要耗掉多少次。</p>
                    <el-button type="text" style="color: #DCDFE6; padding: 0" icon="el-icon-info" slot="reference"></el-button>
                  </el-popover>
                </template>
                <template slot-scope="scope">
                  <el-input type="text" v-model="scope.row.ConsumeAmount" size="small" v-enter-number>
                    <template slot="append">次</template>
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column label="最高消耗次数" width="135px">
                <template slot="header">
                  最高消耗次数
                  <el-popover placement="top-start" width="200" trigger="hover">
                    <p>通用次卡消耗项目时，项目最多消耗次数。</p>
                    <p>0表示不限制消耗次数。</p>
                    <el-button type="text" style="color:#DCDFE6; padding: 0" icon="el-icon-info" slot="reference"></el-button>
                  </el-popover>
                </template>
                <template slot-scope="scope">
                  <el-input type="text" v-model="scope.row.LimitConsumeTimes" size="small" v-enter-number>
                    <template slot="append">次</template>
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="deleteSelectRow(scope.row, scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </template>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancelAddScopeOfApp" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" @click="submitAddScopeOfApp" v-prevent-click>确 认</el-button>
      </div>
    </el-dialog>
    <!--添加分类弹出框-->
    <el-dialog title="添加分类" :visible.sync="addClassificationDialog" width="30%">
      <el-form :model="addClassificationRuleForm" :rules="addClassificationRuleForm_rules" ref="addClassificationRuleForm" label-width="100px" class="demo-ruleForm" size="small">
        <el-form-item label="分类名称" prop="Name">
          <el-input v-model="addClassificationRuleForm.Name" placeholder="请输入分类名称"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancelAddClassificationForm('addClassificationRuleForm')" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" @click="submitAddClassificationForm('addClassificationRuleForm')" v-prevent-click>确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/iBeauty/Goods/generalCard";
import permission from "@/components/js/permission.js";
import dateTime from "@/components/js/date";

import goodsDetail from "../Goods/Components/GoodsDetail.vue";
import showGoodsDetail from "../Goods/Components/ShowGoodsDetail.vue";
import utils from "@/components/js/utils.js";

var Enumerable = require("linq");
export default {
  name: "GeneralCard",
  /**  引入的组件  */
  components: {
    goodsDetail,
    showGoodsDetail,
  },
  data() {
    return {
      loading: false,
      updateLoading: false,
      generalCardSaleEntityList: [], // 通用次卡销售范围
      generalCardCategoryList: [], // 通用次卡分类
      generalCardConsumpEntityList: [], // 通用次卡消耗范围
      addClassificationDialog: false, // 添加分类弹出框状态
      generalCardList: [], // 通用次卡表格数据
      generalCardCategoryID: "", // 所选中的分类ID
      searchKey: "", // 搜索框数据
      isUpperShelf: true, // 是否上架
      ID: "", // 所选中的用户ID
      generalCardProjectList: [], // 适用项目数据
      dialogVisible: false, // 增加、编辑弹出框状态
      showDialogVisible: false, //查看弹出层
      dialogTitle: "", // 增加、编辑弹出框标题,
      entityList: [],
      selectedTableData: [], // 已选适用项目
      defaultCheckedKeysSale: [],
      defaultExpandedKeysSale: [1],
      defaultCheckedKeysConsume: [],
      defaultExpandedKeysConsume: [1],
      defaultCheckedKeysApplyApp: [], // 默认选中的适用项目节点
      filterProjectName: "", //适用项目table过滤
      scopeDefaultProps: {
        children: "Child",
        label: "EntityName",
      }, // 销售范围选择配置项
      addClassificationRuleForm: {
        Name: "",
      }, // 添加分类表单
      addClassificationRuleForm_rules: {
        Name: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
      }, // 添加分类表单验证
      ruleForm_add: {
        Name: "",
        Alias: "",
        specification: "",
        Amount: "",
        unit: "",
        Price: "",
        IsAllowSell: "",
        GeneralCardCategoryID: "",
        saleDate: "",
        ValidType: "",
        IsAllowLargess: "",
        cycleNumber: "",
        ValidDay: "",
        Remark: "",
        Memo: "",
        ImageList: [],
      }, // 增加、编辑弹出框表单
      rules_add: {
        Name: [
          { required: true, message: "请输入通用次卡名称", trigger: "blur" },
        ],
        Price: [{ required: true, message: "请输入销售价格", trigger: "blur" }],
        IsAllowSell: [
          { required: true, message: "请选择是否可以上架", trigger: "change" },
        ],
        GeneralCardCategoryID: [
          { required: true, message: "请选择通用次卡分类", trigger: "change" },
        ],
        saleDate: [
          { required: true, message: "请选择售卖日期", trigger: "change" },
        ],
        ValidType: [
          { required: true, message: "请选择卡有效期", trigger: "change" },
        ],
        IsAllowLargess: [
          { required: true, message: "请选择是否可以赠送", trigger: "change" },
        ],
        Amount: [{ required: true, message: "请输入卡次数", trigger: "blur" }],
        ValidDay: [
          { required: true, message: "请输入卡有效天数", trigger: "blur" },
        ],
      }, // 增加、编辑弹出框表单验证规则
      addScopeDialogState: false, // 设置明细弹出框状态
      filterText: "", // 设置明细搜索框数据
      generalCardProject: [], // 通用次卡适用项目列表
      defaultProps: {
        children: "Child",
        label: "Name",
      }, // el-tree 数据配置
      activeName: "1", // 当前标签页
      paginations: {
        page: 1, // 当前位于哪页
        total: 5, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      }, //需要给分页组件传的信息
      isGeneralCardEdit: "", //编辑权限
      isGeneralCardNew: "", //新增权限
    };
  },
  computed: {
    GeneralCardCategory: function () {
      if (!this.ruleForm_add.GeneralCardCategoryID) return "";
      const value = this.ruleForm_add.GeneralCardCategoryID.toString();
      const item = this.generalCardCategoryList.find(
        (item) => item.ID == value
      );
      return item.Name;
    },
    saleDate: function () {
      return (
        this.ruleForm_add.SaleStartDate +
        " " +
        "至" +
        " " +
        this.ruleForm_add.SaleEndingDate
      );
    },
  },
  methods: {
    // 搜索
    handleSearch: function () {
      var that = this;
      that.paginations.page = 1;
      that.getGeneralCardList();
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.getGeneralCardList();
    },
    // 获取通用次卡列表
    getGeneralCardList: function () {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        Name: that.searchKey,
        GeneralCardCategoryID: that.generalCardCategoryID,
        IsAllowSell: that.isUpperShelf,
      };
      API.getGeneralCardList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
            that.generalCardList = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 新增
    showAddDialog: function () {
      var that = this;
      that.activeName = "1";
      that.dialogTitle = "新增通用次卡";
      that.ruleForm_add = {
        IsAllowSell: true,
        IsAllowLargess: false,
        ValidType: 3,
        Memo: "",
        ImageList: [],
      };
      that.generalCardProjectList = [];
      that.generalCardSaleEntityList = [];
      that.generalCardConsumpEntityList = [];
      that.defaultCheckedKeysSale = [];
      that.defaultExpandedKeysSale = [1];
      that.defaultCheckedKeysConsume = [];
      that.defaultExpandedKeysConsume = [1];
      Object.assign(that.generalCardSaleEntityList, that.entityList);
      Object.assign(that.generalCardConsumpEntityList, that.entityList);
      that.getFindCategoryAndProject();
      that.getGeneralCardCategory();
      that.getEntityList();
      that.dialogVisible = true;
    },
    // 编辑/查看
    showEditDialog: function (row, type) {
      var that = this;
      that.ID = row.ID;
      that.ruleForm_add = Object.assign({}, row);
      that.$set(that.ruleForm_add, "saleDate", [
        new Date(row.SaleStartDate),
        new Date(row.SaleEndingDate),
      ]);
      that.dialogTitle = type == "edit" ? "编辑通用次卡" : "通用次卡详情";
      that.activeName = "1";
      that.generalCardSaleEntityList = [];
      that.generalCardConsumpEntityList = [];
      that.defaultCheckedKeysSale = [];
      that.defaultExpandedKeysSale = [1];
      that.defaultCheckedKeysConsume = [];
      that.defaultExpandedKeysConsume = [1];
      Object.assign(that.generalCardSaleEntityList, that.entityList);
      Object.assign(that.generalCardConsumpEntityList, that.entityList);
      that.getGeneralCardSaleEntity();
      that.generalCardConsumeEntity();
      that.getGeneralCardProject();
      that.getFindCategoryAndProject();
      that.getGeneralCardCategory();
      that.getEntityList();
      that.getGeneralCardDetails(that.ruleForm_add.ID);
      that.dialogVisible = type == "edit" ? true : false;
      that.showDialogVisible = type == "show" ? true : false;
    },
    // 增加、编辑表单确认事件
    submitForm(ruleForm_add) {
      var that = this;
      that.$refs[ruleForm_add].validate((valid) => {
        if (valid) {
          var generalCardProject = [];
          for (let i = 0; i <= that.generalCardProjectList.length - 1; i++) {
            var obj = {};
            if (that.generalCardProjectList[i].ProjectID) {
              obj.ProjectID = that.generalCardProjectList[i].ProjectID;
            } else {
              obj.ProjectID = that.generalCardProjectList[i].ID;
            }
            obj.ConsumeAmount = that.generalCardProjectList[i].ConsumeAmount;
            obj.LimitConsumeTimes = that.generalCardProjectList[i]
              .LimitConsumeTimes
              ? that.generalCardProjectList[i].LimitConsumeTimes
              : 0;
            generalCardProject.push(obj);
          }
          // 售卖日期
          that.ruleForm_add.SaleStartDate = that.ruleForm_add.saleDate[0];
          that.ruleForm_add.SaleEndingDate = that.ruleForm_add.saleDate[1];
          // 获取所选中的销售范围的keys
          that.ruleForm_add.GeneralCardSaleEntity =
            that.$refs.treeSale.getCheckedKeys(); // 销售范围参数
          that.ruleForm_add.GeneralCardConsumeEntity =
            that.$refs.treeConsume.getCheckedKeys(); // 消耗范围
          that.ruleForm_add.GeneralCardProject = generalCardProject; // 适用项目
          if (that.dialogTitle == "编辑通用次卡") {
            if (that.ruleForm_add.ValidType != 3) {
              if (that.ruleForm_add.ValidDay) {
                that.updateGeneralCard();
              } else {
                that.$message.error("请填写卡有效期");
                return false;
              }
            } else {
              that.updateGeneralCard();
            }
          } else {
            that.createGeneralCard();
          }
        }
      });
    },
    // 新增通用次卡
    createGeneralCard: function () {
      var that = this;
      that.loading = true;
      that.updateLoading = true;
      var params = that.ruleForm_add;
      params.Memo = that.$refs["goodsDetail"].getMemo();
      params.ImageList = Enumerable.from(that.ruleForm_add.ImageList)
        .select((val) => ({ ImageUrl: val.url }))
        .toArray();

      API.createGeneralCard(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getGeneralCardList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
          that.updateLoading = false;
        });
    },
    // 更新通用次卡
    updateGeneralCard: function () {
      var that = this;
      that.loading = true;
      that.updateLoading = true;
      var params = that.ruleForm_add;
      params.Memo = that.$refs["goodsDetail"].getMemo();
      params.ImageList = Enumerable.from(that.ruleForm_add.ImageList)
        .select((val) => ({ ImageUrl: val.url }))
        .toArray();

      API.updateGeneralCard(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getGeneralCardList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
          that.updateLoading = false;
        });
    },
    // 获取通用次卡分类
    getGeneralCardCategory: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: "",
        Active: true,
      };
      API.getGeneralCardCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.generalCardCategoryList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取项目分类以及分类下项目
    getFindCategoryAndProject: function () {
      var that = this;
      that.loading = true;
      var params = {
        IsAllowLargess: "",
      };
      API.getFindCategoryAndProject(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.setAttrRecursion(res.Data);
            that.generalCardProject = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取通用次卡适用项目
    getGeneralCardProject: function () {
      var that = this;
      that.loading = true;
      var params = {
        ID: that.ID,
      };
      API.getGeneralCardProject(params)
        .then((res) => {
          if (res.StateCode == 200) {
            res.Data.forEach(function (item) {
              item.ID = item.ProjectID;
              item.PID = "1" + item.ProjectID;
            });
            that.generalCardProjectList = res.Data;
            that.defaultCheckedKeysApplyApp = [];
            that.selectedTableData = [];
            that.generalCardProjectList.forEach((val) => {
              that.defaultCheckedKeysApplyApp.push(val.PID);
            });
            that.selectedTableData = Object.assign(
              [],
              that.generalCardProjectList
            );
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 递归设置适用项目中项目的属性
    setAttrRecursion(val) {
      var that = this;
      val.forEach((item) => {
        if (item.IsProject) {
          item.PID = "1" + item.ID;
          item.ConsumeAmount = "1";
          item.LimitConsumeTimes = "0";
        } else {
          item.PID = "0" + item.ID;
        }
        if (item.Child) {
          that.setAttrRecursion(item.Child);
        }
      });
    },
    // 获取通用次卡销售范围
    getGeneralCardSaleEntity: function () {
      var that = this;
      that.loading = true;
      var params = {
        ID: that.ID,
      };
      API.getGeneralCardSaleEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.defaultCheckedKeysSale = res.Data;
            that.defaultExpandedKeysSale = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取销售范围、消耗范围权限范围
    getEntityList: function () {
      var that = this;
      that.loading = true;
      var params = {
        SearchKey: "",
        Active: "",
      };
      API.getEntityList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取通用次卡消耗范围
    generalCardConsumeEntity: function () {
      var that = this;
      that.loading = true;
      var params = {
        ID: that.ID,
      };
      API.generalCardConsumeEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.defaultCheckedKeysConsume = res.Data;
            that.defaultExpandedKeysConsume = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 创建通用次卡分类
    createGeneralCardCategory: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.addClassificationRuleForm.Name,
      };
      API.createGeneralCardCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.$refs["addClassificationRuleForm"].resetFields();
            that.addClassificationDialog = false;
            that.getGeneralCardCategory();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 适用项目弹框搜索事件
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },
    // 选择适用项目事件

    selectApplicableItems(item, list) {
      var that = this;
      if (that.selectedTableData.length != 0) {
        that.selectedTableData.forEach((val) => {
          list.checkedNodes.forEach((item) => {
            if (val.PID == item.PID) {
              item.ConsumeAmount = val.ConsumeAmount;
              item.LimitConsumeTimes = val.LimitConsumeTimes;
            }
          });
        });
      }
      that.selectedTableData = [];
      list.checkedNodes.forEach((item) => {
        if (item.IsProject) {
          that.selectedTableData.push(item);
        }
      });
      that.selectedTableData = Array.from(new Set(that.selectedTableData));
    },
    // 添加分类点击事件
    addClassification() {
      var that = this;
      that.addClassificationDialog = true;
    },
    // 提交添加分类表单
    submitAddClassificationForm(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.createGeneralCardCategory();
        }
      });
    },
    // 添加分类表单取消按钮点击事件
    cancelAddClassificationForm(formName) {
      var that = this;
      that.$refs[formName].resetFields();
      that.addClassificationDialog = false;
    },
    // 添加适用范围表单取消按钮点击事件
    cancelAddScopeOfApp() {
      var that = this;
      that.selectedTableData = Object.assign([], that.generalCardProjectList);
      var defaultCheckedKeys = Enumerable.from(that.selectedTableData)
        .select((val) => val.PID)
        .toArray();
      that.$nextTick(() => {
        that.$refs.treeRef.setCheckedKeys(defaultCheckedKeys);
      });
      that.addScopeDialogState = false;
    },
    // 添加适用项目弹出框
    addSetDetails() {
      var that = this;
      if (that.dialogTitle == "新增") {
        that.defaultCheckedKeysApplyApp = [];
        that.selectedTableData = [];
        that.generalCardProjectList = [];
      }
      that.addScopeDialogState = true;
    },
    // 提交添加适用范围表单
    submitAddScopeOfApp() {
      var that = this;
      if (that.selectedTableData.length == 0) {
        that.$message.error("请选择项目");
        return false;
      } else {
        var state = true;
        that.selectedTableData.forEach((item) => {
          if (!item.ConsumeAmount) {
            that.$message.error("请输入耗卡次数");
            state = false;
            return false;
          }
        });
        if (state) {
          that.generalCardProjectList = Object.assign(
            [],
            that.selectedTableData
          );
          that.addScopeDialogState = false;
        }
      }
    },
    // 删除所选中的适用项目
    deleteSelectRow(row, index) {
      var that = this;
      that.selectedTableData.splice(index, 1);
      var defaultCheckedKeys = Enumerable.from(that.selectedTableData)
        .select((val) => val.PID)
        .toArray();
      that.$refs.treeRef.setCheckedKeys(defaultCheckedKeys);
    },
    // 批量设置点击事件
    dropdownClick() {},

    // 单位选中事件
    handleCheckAllChange(row) {
      var that = this;
      if (row.checked) {
        if (row.children) {
          row.children.forEach((items) => {
            items.checked = true;
            that.handleCheckAllChange(items);
          });
        }
      } else {
        if (row.children) {
          row.children.forEach((items) => {
            items.checked = false;
            that.handleCheckAllChange(items);
          });
        }
      }
    },

    /**    */
    Memo_change(val) {
      let that = this;
      that.ruleForm_add.Memo = val;
    },
    /**    */
    ImageListChange(val) {
      let that = this;
      that.ruleForm_add.ImageList = val;
    },

    /**  请求详情  */
    getGeneralCardDetails(ID) {
      let that = this;
      let param = { ID: ID };
      API.getGeneralCardDetails(param)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$set(that.ruleForm_add, "ImageList", []);
            that.$set(that.ruleForm_add, "Memo", "");
            that.$set(that.ruleForm_add, "OriginalText", "");
            that.$set(that.ruleForm_add, "ApplyNum", "");
            res.Data.ImageUrlList.forEach((item) => {
              utils.getCanvasBase64(item.ImageUrl).then((val) => {
                that.ruleForm_add.ImageList.push({ url: val });
              });
            });
            that.ruleForm_add.Memo = res.Data.Memo;
            that.ruleForm_add.OriginalText = res.Data.OriginalText;
            that.ruleForm_add.ApplyNum = res.Data.ApplyNum;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
  },
  watch: {
    filterText(val) {
      var that = this;
      that.$refs.treeRef.filter(val);
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isGeneralCardEdit = permission.permission(
        to.meta.Permission,
        "iBeauty-Goods-GeneralCard-Update"
      );
      vm.isGeneralCardNew = permission.permission(
        to.meta.Permission,
        "iBeauty-Goods-GeneralCard-Create"
      );
    });
  },
  mounted() {
    var that = this;
    that.isGeneralCardEdit = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Goods-GeneralCard-Update"
    );
    that.isGeneralCardNew = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Goods-GeneralCard-Create"
    );
    that.getGeneralCardList();
    that.getGeneralCardCategory();
    that.getEntityList();
  },
};
</script>

<style lang="scss">
.GeneralCard {
  .radio_input {
    width: 120px;
    margin-left: 15px;
  }
  .el-table {
    .caret-wrapper {
      height: 23px;
      .ascending {
        top: 0px;
      }
      .descending {
        bottom: 1px;
      }
    }
  }
  .generalShow {
    .el-form-item__label {
      color: #666;
    }
  }
  .el-scrollbar_height {
    height: 60vh;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
}
</style>
