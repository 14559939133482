/**
 * Created by jerry on 2020/03/24.
 * 通用次卡api
 */
import * as API from '@/api/index'

export default {
    // 获取通用次卡分类列表
    getGeneralCardCategory: params => {
        return API.POST('api/generalCardCategory/all',params)
    },
    // 获取通用次卡列表
    getGeneralCardList: params => {
        return API.POST('api/generalCard/list',params)
    },
    // 获取项目分类以及分类下项目
    getFindCategoryAndProject: params => {
        return API.POST('api/generalCard/findCategoryAndProject',params)
    },
    // 获取通用次卡适用项目
    getGeneralCardProject: params => {
        return API.POST('api/generalCard/generalCardProject',params)
    },
    // 获取通用次卡销售范围
    getGeneralCardSaleEntity: params => {
        return API.POST('api/generalCard/generalCardSaleEntity',params)
    },
    // 获取通用次卡消耗范围
    generalCardConsumeEntity: params => {
        return API.POST('api/generalCard/generalCardConsumeEntity',params)
    },
    // 创建通用次卡分类
    createGeneralCardCategory: params => {
        return API.POST('api/generalCardCategory/create',params)
    },
    // 更新通用次卡信息
    updateGeneralCard: params => {
        return API.POST('api/generalCard/update',params)
    },
    // 创建通用次卡
    createGeneralCard:params => {
        return API.POST('api/generalCard/create',params)
    },
    // 新增销售范围、消耗范围 获取权限范围
    getEntityList:params => {
        return API.POST('api/entity/list',params)
    },
    // 通用次卡详情
    getGeneralCardDetails:params => {
      return API.POST('api/generalCard/getGeneralCardDetails',params)
    },
}
